import React, { useEffect, useLayoutEffect, useState } from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from 'src/hooks/window/useWidth'

import { ImgWebp } from 'src/styles/imgWebp'

import * as S from './style'

import { getParameterByName } from 'src/shared/helpers'

import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import { alphaCode } from 'src/utils/alpha-code-utms'

type ModalProps = {
  setIsOpen: Function;
  setIsModalOpen: Function;
  setDataLayer: (state: IDataLayerParams) => void;

}

const WIDTH_MD = 768

const Hero = ({ setIsOpen, setIsModalOpen, setDataLayer }: ModalProps) => {
  const [ utmSource, setUtmSource ] = useState<string>('9io9bkj')
  const [ utmCampaign, setUtmCampaign ] = useState<string>('site_contadigital_aquisicao')

  const [ isMobile, setIsMobile ] = useState(true)
  const width = useWidth(300)
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    element_action: 'click button',
    element_name: 'Abra sua conta',
    section_name: 'Você e seu amigco ganham até R$1.000 em pontos Loop',
  }

  useLayoutEffect(() => {
      setIsMobile(width < WIDTH_MD)
  }, [ width ])

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', '') as string || '9io9bkj')
    setUtmCampaign(getParameterByName('utm_campaign', '') as string || 'site_contadigital_aquisicao')
  }, [])

  return (
    <S.HeroSection
      className='d-flex'
      role='img'
      aria-label='Duas amigas sorridentes que estão lado a lado. À esquerda, temos uma mulher negra com cabelos longos trançados, já à direita, temos uma mulher branca com cabelo de cumprimento médio, ruivo e uma franja.'
    >
      <div className='container'>
        <div className='row d-flex align-items-md-center justify-content-end'>
          <div className='col-12 col-md-6 col-lg-5'>
            <ImgWebp
              src='https://central-imagens.bancointer.com.br/images-without-small-versions/herobanner-indique-e-ganhe/image.webp'
              breakPointsSize={{ sm: '100%' }}
              alt='duas mulheres olhando celular. Na parte inferior há o logotipo da campanha Indique e Ganhe'
            />
          </div>
          <div className='col-12 col-md-6 col-xl-5 offset-lg-1 offset-xl-2'>
            <h1 className='fs-24 lh-30 fs-md-26 lh-md-32 fs-lg-40 lh-lg-48 fw-500 mb-3 mt-xl-5'>
              Indique e Ganhe até <span className='text-orange--extra'>40.000</span> pontos Loop
            </h1>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-4'>
              Indique seus amigos para abrir conta no Inter
              e acumule pontos para trocar pelos benefícios que mais combinam com você!
            </p>
            {
              isMobile ? (
                <S.CTAMobile
                  className='d-flex justify-content-center align-items-center'
                  href='https://inter-co.onelink.me/Qyu7/k75694nf'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      element_action: 'click button',
                      element_name: 'Convide seus amigos',
                      section_name: 'Você e seu amigo ganham até R$1.000 em pontos Loop',
                      redirect_url: 'https://inter-co.onelink.me/Qyu7/k75694nf',
                    })
                  }}
                >
                  Convide seus amigos
                </S.CTAMobile>
              ) : (
                <S.Button
                  onClick={() => {
                    setIsOpen(true)
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      element_action: 'click button',
                      element_name: 'Convide seus amigos',
                      section_name: 'Você e seu amigco ganham até R$1.000 em pontos Loop',
                    })
                  }}
                >
                  Convide seus amigos
                </S.Button>
              )
            }
            {
              isMobile ? (
                <S.ButtonReverse
                  href='https://inter-co.onelink.me/Qyu7/7zg3z3vd'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      element_action: 'click button',
                      element_name: 'Abra a sua conta',
                      section_name: 'Você e seu amigco ganham até R$1.000 em pontos Loop',
                      redirect_url: 'https://inter-co.onelink.me/Qyu7/7zg3z3vd',
                    })
                  }}
                >
                  Abra a sua conta
                </S.ButtonReverse>
              ) : (
                <S.ButtonReverse
                  onClick={() => {
                    setIsModalOpen(true)
                    sendDatalayerEvent(dataLayer)
                    setDataLayer(dataLayer)
                  }}
                >
                  Abra sua conta
                </S.ButtonReverse>
              )
            }
          </div>
        </div>
      </div>
    </S.HeroSection>
  )
}

export default Hero
